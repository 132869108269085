.footer-wrapper{
    color: #ffffff;
    background-color: var(--theme-second-color);
    padding: 0;
    margin: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    bottom: 0;
    left: 0;
    font-size: 12px;
    ul{
        padding-top: 20px;
    }
    li{
        text-decoration: none;
        list-style-type: none; /* Remove bullets */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margins */
        margin-top: 2px;
        cursor:pointer;
    }
    a{
        color: #ffffff;
        &:focus, &:hover, &:visited, &:link, &:active {
            color: #ffffff;
        }
    }
}