.notfound-img{
    height: 30%;
}

@media all and (min-width: 768px) and (max-width: 1002px) {
    .notfound-img{
        padding-top: 40px;
        padding-bottom: 10px;
        width: 80%;
    }
}

@media all and (min-width: 576px) and (max-width: 767px) {
    .notfound-img{
        padding-top: 40px;
        padding-bottom: 10px;
        width: 40%;
    }
}

@media all and (min-width: 421px) and (max-width: 575px) {
    .notfound-img{
        padding-top: 80px;
        padding-bottom: 10px;
        width: 60%;
    }
}


@media all and (max-width: 420px) {
    /* For mobile phones: */
    .notfound-img{
        padding-top: 20px;
        padding-bottom: 30px;
        width: 60%;
    }
}