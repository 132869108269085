.subtitle{
    font-style: normal;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    span{
        font-size: 28px;
        color: #FF5777;
        font-weight: bold;
    }
}

.title{
    font-weight: 800;
    font-size: 6vh;
    line-height: 6.4vh; 
    p{
        display: inline;
    }  
}

.text{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    // line-height: 19,98px;s
    padding-top:10px;
    padding-bottom:10px;
}

/* .highlighted-text{
    border: 3px solid var(--theme-square);  
    padding: 0em 0.1em;
} */

.highlighted-text{
    position:relative;
}

.highlighted-text:before{
    content:"";
    z-index:-1;
    left:0.1em;
    top:0.1em;
    border-width:3px;
    border-style:solid;
    border-color:var(--theme-square);
    position:absolute;
    border-right-color:transparent;
    border-left-color:transparent;
    width:100%;
    height:1.1em;
    transform:rotate(1deg);
    opacity:0.5;
    border-radius:0.25em;
}

.highlighted-text:after{
    content:"";
    z-index:-1;
    left:0em;
    top:0.15em;
    border-width:3px;
    border-style:solid;
    border-color:var(--theme-square);
    //border-left-color:transparent;
    //border-top-color:transparent;
    position:absolute;
    width:100%;
    height:1em;
    transform:rotate(-2deg);
    opacity:0.5;
    border-radius:0.25em;
}





@media all and (min-width: 1920px) and (max-width: 3991px) { 
    .title{
        font-size: 5.4vh;
        line-height: 5.8vh; 
    }
    .text{
        font-size: 12px;
    }
}

/* Tablet in modalità orizzontale */
@media all and (min-width: 768px) and (max-width: 991px) { 
    .title{
        font-size: 3.5vh;
        line-height: 3.8vh; 
    }
    .text{
        font-size: 12px;
    }
}

@media all and (min-width: 576px) and (max-width: 767px) {
    .subtitle{
        font-size: 16px;
    }
    .title{
        font-size: 4.4vh;
        line-height: 4.8vh;  
    }
    .text{
        font-size: 12px;
        padding-bottom:0px;
    }
}

/* Tablet in modalità verticale */
@media all and (min-width: 421px) and (max-width: 575px) {
    .subtitle{
        font-size: 16px;
        margin-bottom: 0px;
    }
    .title{
        font-size: 4.4vh;
        line-height: 4.8vh;
        margin-bottom: 0px;  
    }
    .text{
        font-size: 12px;
        padding-top:0px;
        padding-bottom:0px;
    }
    .subtitle{
        font-size: 13px;
    }
}

/* Smartphone o piccoli Tablet */
@media all and (max-width: 420px) {
    .title{
        font-size: 3.8vh;
        line-height: 4.2vh;  
    }
    .text{
        font-size: 12px;
        padding-bottom:0px;
    }
    .subtitle{
        font-size: 13px;
    }
}