.about-wrapper{
    background-color: var(--theme-stripes-one);
    min-height: 630px;
}


.about-img{
    width: 90%;
}
@media all and (min-width: 768px) and (max-width: 1002px) {
    .about-img{
        padding-top: 40px;
        padding-bottom: 10px;
        width: 80%;
    }
}

@media all and (min-width: 576px) and (max-width: 767px) {
    .about-img{
        padding-top: 40px;
        padding-bottom: 10px;
        width: 40%;
    }
}

@media all and (min-width: 421px) and (max-width: 575px) {
    .about-img{
        padding-top: 80px;
        padding-bottom: 10px;
        width: 60%;
    }
}


@media all and (max-width: 420px) {
    /* For mobile phones: */
    .about-img{
        padding-top: 20px;
        padding-bottom: 30px;
        width: 60%;
    }
}