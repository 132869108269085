.work-wrapper{
    min-height: 100vh;
    color: var(--theme-text);
    background-color: var(--theme-page-background);
}

.work-img{
    width: 70%;
}

@media all and (min-width: 576px) and (max-width: 767px) {
    .work-img{
        padding-top: 40px;
        padding-bottom: 10px;
        width: 40%;
    }
}

@media all and (min-width: 421px) and (max-width: 575px) {
    .work-img{
        padding-top: 80px;
        padding-bottom: 10px;
        width: 60%;
    }
}


@media all and (max-width: 420px) {
    /* For mobile phones: */
    .work-img{
        padding-top: 40px;
        padding-bottom: 30px;
        width: 60%;
    }
}