.skill-wrapper{
    background-color: var(--theme-stripes-two);
    min-height: 100vh

}

.skill-img{
    width: 60%;
    padding-top: 10px;
    margin-bottom: -30px;
}

@media all and (min-width: 576px) and (max-width: 767px) {
    .skill-img{
        padding-top: 40px;
        padding-bottom: 10px;
        width: 40%;
    }
}

@media all and (min-width: 421px) and (max-width: 575px) {
    .skill-img{
        padding-top: 80px;
        padding-bottom: 10px;
        width: 60%;
    }
}


@media all and (max-width: 420px) {
    /* For mobile phones: */
    .skill-img{
        padding-top: 40px;
        padding-bottom: 30px;
        width: 60%;
    }
}

.skill-video{
    width: 80%;
    margin-bottom: -60px;
}

@media all and (min-width: 576px) and (max-width: 767px) {
    .skill-video{
        padding-top: 40px;
        padding-bottom: 50px;
        width: 60%;
    }
}

@media all and (min-width: 421px) and (max-width: 575px) {
    .skill-video{
        padding-top: 80px;
        padding-bottom: 10px;
        width: 80%;
    }
}


@media all and (max-width: 420px) {
    /* For mobile phones: */
    .skill-video{
        padding-top: 40px;
        padding-bottom: 30px;
        width: 80%;
    }
}