/* 
   Warning: if you want to re-use the scss for this switch remember to change
   the variable use in "input:checked +.slider{}" to a color of your choice
*/


.switch {
    display: flex;
    height: 27px;
    position: relative;
    width: 56px;
    cursor: pointer;
}

.switch input {
    display:none;
}

.slider {
    background-color: rgb(0, 0, 0);
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}

.slider:before {
    background-color: rgb(255, 255, 255);
    content: "";
    height: 24px;
    left: 2px;
    top:1.5px;
    position: absolute;
    transition: .4s;
    width: 24px;
    z-index:421;
}

input:checked + .slider {
    background-color: var(--theme-text);
}

input:checked + .slider:before {
    transform: translateX(27px);
    background-color: #000000;
}

.slider.round {
    border-radius: 100px;
}

.slider.round:before {
    border-radius: 100%;
}

.toggle-moon, .toggle-sun {
    width:50%;
    text-align:center;
    color: white;
    position:relative;
    z-index:420;
}
