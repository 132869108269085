.contact-wrapper{
    min-height:90vh;
    color: var(--theme-text);
    background-color: var(--theme-page-background);
}

.form-button{
    font-weight: bold !important;
    cursor: url('../media/svg/cursor-pointer.svg'), pointer !important; 
}

.contact-img{
    width: 70%;
    margin-bottom: -30px;
}

@media all and (min-width: 576px) and (max-width: 767px) {
    .contact-img{
        padding-top: 40px;
        padding-bottom: 10px;
        width: 40%;
    }
}

@media all and (min-width: 421px) and (max-width: 575px) {
    .contact-img{
        padding-top: 80px;
        padding-bottom: 20px;
        width: 60%;
    }
}


@media all and (max-width: 420px) {
    /* For mobile phones: */
    .contact-img{
        padding-top: 40px;
        padding-bottom: 50px;
        width: 60%;
    }
}