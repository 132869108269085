.header-wrapper{
    .navbar{
        padding-left: 30px;
        padding-right: 30px;
    }
}


// ------------------------- 
// Animation on theme change
// -------------------------  

.circle {
    width: 0px;
    height: 0px;
    position: absolute;
    top: -100px;
    right: -100px;
    z-index: -900;
    border-radius: 50%;
    background: none;    
}

.circle-toggle-dark{
    width: 500vh;
    height: 500vh;
    top: -100vh;
    right: -100vh;
    background: #0a0d13;
    animation-name: circle-animation;
    animation-duration: 1s;
}

.circle-toggle-light{
    width: 500vh;
    height: 500vh;
    top: -100vh;
    right: -100vh;
    background: #f1f1f1;
    animation-name: circle-animation;
    animation-duration: 1s;
}

@keyframes circle-animation {
  0%   {width: 0vh; height: 0vh;}
  50% {width: 500vh; height: 500vh;}
  100% {width: 0vh; height: 0vh;}
}




    