.App{
    height: 100vh;
    font-family: 'Inter', sans-serif;
    background: var(--theme-page-background);
    color: var(--theme-text);
    
    cursor: url('../media/svg/cursor-default.svg'), auto;
    
    

    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */

    //Hiding scroll bar in the website in IE, Edge, Firefox
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
}

//Hiding scroll bar in the website in Chrome, Safari, and Opera
.App::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

a{
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
}

//custom pointer cursor
.cursor-pointer{
    cursor: url('../media/svg/cursor-pointer.svg'), pointer !important;
}

//need anchor to be before the element for offset
.anchor{
    position:relative;
    bottom: 8vh;
}