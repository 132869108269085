.card-wrapper{
    border: 3px solid var(--theme-second-color);
    padding: 15px;
    padding-left: 40px;
    border-radius: 8px;
}

.card-title{
    color: var(--theme-text);
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    display: flex;
    padding-top:10px;
    span{
        font-size: 18px;
        font-weight: 700;
        letter-spacing: -0.155em;
        padding-right: 10px;
    } 
}

.card-text{
    color: var(--theme-card-text);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19,98px;
    padding-top:10px;
}

.vertical-center {
    margin: 0;
    position: relative;     
    top: 45%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}