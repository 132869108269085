.light{
    //page
    --theme-page-background: #f1f1f1;
    --theme-text: #1b1b1b;
    //stripes
    // --theme-stripes-one: #FFDA00;
    // --theme-stripes-two: #FF5777;
    --theme-stripes-one: #ffffff;
    --theme-stripes-two: #ffffff;
    //button
    --theme-button-border: #000000;
    --theme-button-text: #000000;
    //footer and card
    --theme-second-color: #131313;
    //square
    --theme-square: #0a0d13;
    //cards
    --theme-card-text: #6b6b6b;
}

.dark{
    //page
    --theme-page-background: #0e131a;
    --theme-text: #cecece;
    //stripes
    --theme-stripes-one: #12191F;
    --theme-stripes-two: #12191F;
    //button
    --theme-button-border: #ffffff;
    --theme-button-text: #ffffff;
    //footer and card
    --theme-second-color: #12191F;
    //square
    --theme-square: #FFDA00;
    //cards
    --theme-card-text: #cecece;
}