/* This file is used for change the preset of react-bootstrap */



/* Color customization*/
// $theme-colors: (
//     "primary":#FFDA00,
//     "light": #000000,
//     "dark": #ffffff,
//     "navbar": #A5A5A5,
//     "bg-light":#f1f1f1,
//     "bg-dark":#0a0d13,
// );
$theme-colors: (
    "primary":#000000,
    "light": #000000,
    "dark": #ffffff,
    "navbar": #A5A5A5,
    "bg-light":#f1f1f1,
    "bg-dark":#0e131a, //navbar backgorund color
);

/* Navbar customization */
.nav-link {
    color: #A5A5A5 !important;
    text-decoration: 'none' !important;
    cursor: url('../media/svg/cursor-pointer.svg'), pointer !important; 
}

.nav-link::after {
    color: #A5A5A5 !important;
    text-decoration: 'none' !important;
}

.navbar-brand{
    color: #A5A5A5 !important;
}

.navbar-toggler{
    color: none !important;
    border: none !important;
}
.navbar-toggler-icon{
    background-image: url('../media/svg/menu.svg') !important;
}

.form-control{
    cursor: url('../media/svg/cursor-text.svg'), text !important;
}

.button{
    font-weight: bold !important;
    cursor: url('../media/svg/cursor-pointer.svg'), pointer !important; 
}

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap.scss';